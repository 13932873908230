<template>
  <vs-divider position="left">
  <div class="vs-list--title">โครงการ {{roleInfo != null ? roleInfo.customer_name : ""}}</div>
</vs-divider>
</template>

<script>
import Vue from "vue";
import VueCookies from "vue-cookies";


Vue.use(VueCookies);

export default {
  name: 'customer-header',
  data() {
    return {
      roleInfo: {},
    };
  },
  beforeCreate() {
  },
  props: {
    // navbarColor  : { type: String, default: "#fff", },
    // navMenuItems : { type: Array,  required: true   }
  },
  components: {
  },
  computed: {
  },
  methods: {
  },
  created() {
        this.roleInfo = $cookies.get("roleInfo");
        // console.log("xxxx this.roleInfo=",this.roleInfo);
  }
}
</script>
