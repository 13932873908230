<template>
  <div class="row justify-content-center">
    <customer-header />


    <vs-prompt
      title=""
      @accept="deleteUser"
      accept-text="ลบ"
      cancel-text="ยกเลิก"
      @close="close"
      :active.sync="deletePrompt"
    >
      <div class="con-exemple-prompt">
        ลบข้อมูลนี้?
      </div>
    </vs-prompt>

    <div v-if="enable">
      <vs-list>
        <vs-list-item class="k-nav-bar" title="ข้อมูลปิดเปิดบ้าน" subtitle="">
        </vs-list-item>
      </vs-list>
      <vs-card style="margin-top: 10px">
        <div>
          <vs-table stripe :data="items">
            <template slot="thead" style="margin-top: 100px">
              <vs-th>ลบ </vs-th>
              <vs-th> วันที่ </vs-th>
              <vs-th> บ้านเลขที่ </vs-th>
              <!-- <vs-th> url </vs-th> -->
              <vs-th> หมายเหตุ </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <vs-button
                    @click="openDeletePromt(data[indextr].key)"
                    color="primary"
                    type="flat"
                    icon="delete"
                  ></vs-button>
                </vs-td>

                <!-- <vs-td :data="data[indextr].house_no">
                  {{ createQR(data[indextr].uid) }}
                </vs-td> -->
                <vs-td :data="data[indextr].dttm">
                  {{
                    new Date(data[indextr].dttm.seconds * 1000)
                      | moment("dddd, Do MMMM YYYY")
                  }}
                </vs-td>
                <vs-td :data="data[indextr].house_no">
                  {{ data[indextr].house_no }}
                </vs-td>

                <vs-td :data="data[indextr].desc">
                  {{ data[indextr].desc }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
            <div>
              <!-- <vs-pagination
                v-on:next="nextData"
                v-on:previous="previousData"
                v-on:input="inputData"
                class="mt-2"
                v-model="page"
                :total="total"
                :length="total"
              ></vs-pagination> -->
              <br>
              <paginate
                v-model="page"
                :page-count="total"
                :page-range="3"
                :margin-pages="2"
                :click-handler="inputData"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'">
              </paginate>
            </div>
        </div>
      </vs-card>
    </div>
    <div v-else>
      <the-advertising :post-details="listAdvertising" />
    </div>


  </div>
</template>

<script>
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { db } from "../store/firebaseDb";
const moment = require("moment");
require("moment/locale/th");
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import CustomerHeader from '@/layouts/components/custom/CustomerHeader.vue'
import TheAdvertising from "@/components/advertising/TheAdvertising.vue";
import ListAdvertising from "@/store/listAdvertising.js";
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

console.log("lang", lang);

Vue.use(VueCryptojs);
Vue.use(VueCookies);
Vue.component('customer-header', CustomerHeader);
Vue.component("the-advertising", TheAdvertising);

moment.locale("th");
Vue.use(require("vue-moment"), {
  moment,
});

console.log(Vue.moment().locale());
export default {
  data() {
    return {
      deletePrompt: false,
      updatePrompt: false,
      roleInfo: {},
      language: "en",
      languages: lang,
      houseInfo: {},
      items: [],
      val: "",
      selectedId: "",
      formModel: {
        dttm: "",
        house_no: null,
        desc: "",
      },

      highlightedFn: {
        customPredictor(date) {
          const now = moment(new Date()).format("MMMM Do YYYY");
          const d = moment(date).format("MMMM Do YYYY");
          console.log("now=", now);
          console.log("d=", d);
          if (now == d) {
            return true;
          }
        },
      },
      // pagination
      total: 0, // จำนวนหน้า
      limit: 10,
      page: 1,
      totalItems: 0, // จำนวนข้อมูลทั้งหมด
      isLoad: true,

      user_cloud_config: {},
      enable: null,
      listAdvertising: {}
    };
  },
  computed: {
    validName() {
      console.log(this.formModel);
      // return this.formModel.dttm != null && this.formModel.desc.length > 0;
      return true;
    },
  },
  methods: {

    close() {
      this.formModel = {};
    },

    onUpdateForm(event) {
      event.preventDefault();
      db.collection("users")
        .doc(this.$route.params.id)
        .update(this.user)
        .then(() => {
          console.log("User successfully updated!");
          this.$router.push("/list");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openUpdatePromt(item) {
      console.log("[[openUpdatePromt]]", item);

      this.formModel.house_no = item.house_no
      this.formModel.name = item.name
      this.formModel.email = item.email
      this.formModel.phone = item.phone

      this.selectedId = item.key
      this.updatePrompt = true;
    },

    deleteUser() {
        const customerName = $cookies.get("customer");
        db.collection("customer/" + customerName + "/activity")
          .doc(this.selectedId)
          .delete()
          .then(() => {
            console.log("Document deleted!");
          })
          .catch((error) => {
            console.error(error);
          });
    },

    openDeletePromt(id) {
      this.selectedId = id
      this.deletePrompt = true
    },

    async listHome() {
      const customerName = $cookies.get("customer");

    let totalCustomer = await db
        .collection("customer/" + customerName + "/activity")
        .get()
        .then(document => document.docs.length);
      this.total = Math.ceil(totalCustomer / this.limit);
      this.totalItems = totalCustomer;

      let indexOf = 0;
      let get = db
        .collection("customer/" + customerName + "/activity")
        .orderBy("dttm", "desc")
        .orderBy("house_no", "asc")
      get
        .get()
        .then(document => {
          let last = document.docs[indexOf];
          db.collection("customer/" + customerName + "/activity")
            .orderBy("dttm", "desc")
            .orderBy("house_no", "asc")
            .startAt(last)
            .limit(this.limit)
            .onSnapshot(snapshotChange => {
              this.items = [];
              snapshotChange.forEach(doc => {
              // console.log("list doc.data()", doc.data());
                this.items.push({
                  key: doc.id,
                  house_no: this.getHouseDisplay(doc.data()),
                  dttm: doc.data().dttm,
                  desc: doc.data().desc,
                });
                this.isLoad = false;
              });
            });
        })
        .catch(err => {
          console.log("err", err);
        });

      // db.collection("customer/" + customerName + "/activity")
      //   .orderBy("dttm", "desc")
      //   .orderBy("house_no", "asc")
      //   .onSnapshot((snapshotChange) => {
      //     this.items = [];

      //     snapshotChange.forEach((doc) => {
      //      // console.log("list doc.data()", doc.data());

      //       this.items.push({
      //         key: doc.id,
      //         house_no: doc.data().house_no,
      //         dttm: doc.data().dttm,
      //         desc: doc.data().desc,
      //       });
      //     });
      //   });
    },

    previousData: function() {
      this.fetchHome({ page: this.page - 1, limit: 10});
    },
    nextData: function() {
      this.fetchHome({ page: this.page - 1, limit: 10 });
    },
    inputData: function(value) {
      this.page = value;
      this.fetchHome({ page: this.page - 1, limit: 10 });
    },
    fetchHome: async function({ page = 0, limit = 10 }) {
      this.isLoad = true;
      let indexOf = limit * page;
      // console.log("fetchHome : indexOf ["+indexOf+"] ="+this.total+" * "+page)
      const customerName = $cookies.get("customer");
      let get = db
        .collection("customer/" + customerName + "/activity")
        .orderBy("dttm", "desc")
        .orderBy("house_no", "asc")
      get
        .get()
        .then(document => {
          let last = document.docs[indexOf];
          db.collection("customer/" + customerName + "/activity")
            .orderBy("dttm", "desc")
            .orderBy("house_no", "asc")
            .startAt(last)
            .limit(this.limit)
            .onSnapshot(snapshotChange => {
              this.items = [];
              snapshotChange.forEach(doc => {
              // console.log("list doc.data()", doc.data());
                this.items.push({
                  key: doc.id,
                  house_no: this.getHouseDisplay(doc.data()),
                  dttm: doc.data().dttm,
                  desc: doc.data().desc,
                });
                this.isLoad = false;
              });
            });
        })
        .catch(err => {
          console.log("err", err);
        });
    },

    getHouseDisplay(data){
      if( data.prefix_address == undefined || data.prefix_address == null || data.prefix_address == "" ){
        return data.house_no;
      }
      return data.prefix_address + "/"+data.house_no;
    }

  },
  components: {
    Datepicker,
  },
  created() {

    if( $cookies.get("userInfo") == null ){
          this.$router.push("/pages/login").catch(error => {
              if (error.name != "NavigationDuplicated") {
                          throw error;
              }
            });

    }

    this.roleInfo = $cookies.get("roleInfo");

    this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
    var parameters = this.$route.query;

    var home = $cookies.get("home");
    console.log("home", home);

    this.houseInfo = $cookies.get("houseinfo");
    console.log("this.houseInfo=", this.houseInfo);

    this.listHome();
    // this.getUserRole();

    this.user_cloud_config = $cookies.get("user_cloud_config");
    this.enable = this.user_cloud_config.enable_close_house;
    this.listAdvertising = ListAdvertising.page_close_home;
  },
};
</script>

<style>
.con-exemple-prompt {
  padding: 10px;
  padding-bottom: 0px;
}
.vs-input {
  width: 100%;
  margin-top: 10px;
}
.page-item {
}
</style>
